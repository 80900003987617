import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import PortfolioItem from "../components/portfolio/PortfolioItem"
import PostList from "../components/blog/PostList/styled.js"
import Pagination from "../components/blog/Pagination"
import Leading from "../components/Leading"

import "./style.css"

const PortfolioList = props => {
  
  const postList = props.data.allMarkdownRemark.edges
  
  const { currentPage, numPages } = props.pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? "/" : `/page/${currentPage - 1}`
  const nextPage = `/page/${currentPage + 1}`
  
  return (
    <Layout>
      <SEO title="Portfolio" />
      {isFirst && <Leading title="VEJA MEUS ÚLTIMOS TRABALHOS" subtitle="Aqui estão listados os trabalhos que mais me orgulho, confira!" />}
      <PostList>
        {postList.map(
          ({
            node: {
              frontmatter: { background, category, description, title, type, image },  
              fields: { slug },
            },
          }) => ( type === 'portfolioPost' ?
            <PortfolioItem
              key={slug}
              slug={slug}
              background={background}
              category={category}
              title={title}
              description={description}
              image={image}
            /> : ''
          )
        )}
      </PostList>
      <Pagination
        isFirst={isFirst}
        isLast={isLast}
        currentPage={currentPage}
        numPages={numPages}
        prevPage={prevPage}
        nextPage={nextPage}
      />
    </Layout>
  )
}

export const query = graphql`
  query PortfolioList($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            background
            category
            date(formatString: "DD [de] MMMM [de] YYYY", locale: "pt-br")
            description
            title
            type
            image
          }
          timeToRead
        }
      }
    }
  }
`

export default PortfolioList
