import styled from "styled-components"
import media from "styled-media-query"

export const Container = styled.article`
  display: flex;
  flex-flow: column wrap;
  justify-content: space-around;
  align-items: center;
  max-width: 1000px;
  margin: 30px auto;
  padding: 30px 0;
  text-align: center;

  animation-name: appear;
  animation-duration: 200ms;

  @keyframes appear {
    0% {
      opacity: 0.1;
      transform: translate3d(0, -20px, 0);
    }
    100% {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
`

export const Title = styled.h1`
  letter-spacing: 0.025em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
  font-size: 3.7rem;
  color: var(--main-color);
  font-family: var(--fontSerif);
  line-height: 1;
  font-weight: 600;
  margin: 0;

  ${media.lessThan("large")`
    font-size: 2.8rem;
  `}
`

export const Subtitle = styled.h2`
  font-size: 1.7rem;
  color: var(--main-color);
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  line-height: 1.3;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: var(--fontSansSerif);

  ${media.lessThan("large")`
    font-size: 1.3rem;
    padding: 0 20px;
  `}
`
