import React from "react"

import * as S from "./styled.js"

const Leading = ({ title, subtitle }) => (
  <S.Container>
    <S.Title>{title}</S.Title>
    <S.Subtitle>{subtitle}</S.Subtitle>
  </S.Container>
)

export default Leading
