import styled from "styled-components"
import media from "styled-media-query"

const PostList = styled.section`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  max-width: 1500px;
  margin: auto;

  ${media.lessThan("large")`
    /* flex-flow: column wrap; */
    padding: 0 20px;
  `}
`
export default PostList
