import styled from "styled-components"
import media from "styled-media-query"
import AniLink from "gatsby-plugin-transition-link/AniLink"

export const PortfolioItemLink = styled(AniLink)`
  display: flex;
  text-decoration: none;
  width: 30%;
  transition: all 150ms ease-in-out;

  &:hover,
  &:focus {
    background: var(--cardHoverBg);
    border-radius: 0.25rem;
    box-shadow: 0 2px 12px #958fb318;
    outline: none;

    h1,
    p {
      /* color: var(--purple); */
    }

    img {
      transform: scale(1.03);
      transition: all 150ms ease-in-out;
    }
  }

  ${media.lessThan("large")`
    width: 50%;
  `};

  ${media.lessThan("medium")`
    width: 100%;
  `};
`

export const PortfolioItemWrapper = styled.section`
  align-items: center;
  margin-bottom: 1rem;
  display: flex;
  flex-flow: column;
  padding: 2rem 3rem;
  width: 100%;

  ${media.lessThan("large")`
    padding: 0;
    margin-bottom: 1.3rem;
  `};
`

export const PortfolioItemImage = styled.img`
  border-radius: 0.35rem;
  /* box-shadow: 0 1px 12px #34248442; */
  box-shadow: var(--elevation1);
  transition: all 150ms ease-in-out;
`

export const PortfolioItemInfo = styled.div`
  width: 100%;
  padding: 14px 20px;
`

export const PortfolioItemTitle = styled.h1`
  font-size: 2.25rem;
  letter-spacing: 0.05em;
  margin-bottom: 1rem;
  font-weight: 700;
  line-height: 1;
  /* color: #494361; */
  color: var(--main-color);
  font-family: var(--fontSerif);

  ${media.lessThan("large")`
    font-size: 2.2rem;
  `};
`

export const PortfolioItemDescription = styled.p`
  font-size: 1.1rem;
  padding-right: 1rem;
  font-family: var(--fontSansSerif);
  /* color: #494361; */
  color: var(--text-color);

  ${media.lessThan("large")`
    font-size: 1rem;
  `};
`

export const PortfolioItemCategory = styled.p`
  font-size: 0.9rem;
  /* color: #494361; */
  color: var(--main-color);
  margin-bottom: 5px;

  ${media.lessThan("large")`
    font-size: 1rem;
  `}
`
