import styled from "styled-components"

export const PaginationWrapper = styled.section`
  align-items: center;
  /* color: #38444d; */
  color: var(--main-color);
  display: flex;
  flex-flow: row nowrap;
  padding: 1.5rem 3rem;
  justify-content: space-between;
  max-width: 1480px;
  margin: auto;
  font-size: 1.3rem;
  
  a {
    /* color: #38444d; */
    color: var(--main-color);
    text-decoration: none;
    transition: color 0.5s;
    padding: 20px 30px;

    &:hover {
      background: var(--purple);
      border-radius: 4px;
      box-shadow: 0 1px 6px #000;
      color: #fff;
    }
  }
`
