import React from "react"
import PropTypes from "prop-types"

import * as S from "./styled.js"

const PortfolioItem = ({
  slug,
  background,
  category,
  title,
  description,
  image,
}) => {
  return (
    <S.PortfolioItemLink
      cover
      direction="up"
      bg="#2F2F2F"
      duration={0.6}
      to={slug}
    >
      <S.PortfolioItemWrapper>
        <S.PortfolioItemImage
          background={background}
          src={`/assets/img/portfolio/${image}`}
          alt={category + " - " + title}
        />
        <S.PortfolioItemInfo>
          <S.PortfolioItemCategory>{category}</S.PortfolioItemCategory>
          <S.PortfolioItemTitle>{title}</S.PortfolioItemTitle>
          <S.PortfolioItemDescription>{description}</S.PortfolioItemDescription>
        </S.PortfolioItemInfo>
      </S.PortfolioItemWrapper>
    </S.PortfolioItemLink>
  )
}

PortfolioItem.propTypes = {
  slug: PropTypes.string.isRequired,
  background: PropTypes.string,
  category: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

export default PortfolioItem
